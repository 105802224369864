import request from "@/base/util/request.js";

/**
 * POST
 * ​/qixianggongyingpingtai_api/cmsbackstage/cmsAdInfo/addOrUpdateAdInfo
 * 新增数据
 *  @param {*} data 
 * @returns 
 */
export const addOrUpdateAdInfo = (data) => {
        return request({
            url: "cmsbackstage/cmsAdInfo/addOrUpdateAdInfo",
            method: "post",
            data,
        })

    }
    /**
     * GET
     * /qixianggongyingpingtai_api/cmsbackstage/cmsAdInfo/deleteAdInfoById
     * 删除
     * @param {*} params 
     * @returns
     */
export const deleteAdInfoById = (params) => {
    return request({
        url: "cmsbackstage/cmsAdInfo/deleteAdInfoById",
        method: "get",
        params,
    })

}

/**
 * GET
 * /qixianggongyingpingtai_api/cmsbackstage/cmsAdInfo/queryAdInfoById
 * 详情
 * @param {*} params 
 * @returns
 */
export const queryAdInfoById = (params) => {
    return request({
        url: "cmsbackstage/cmsAdInfo/queryAdInfoById",
        method: "get",
        params,
    })

}

/**
 * POST
 * /qixianggongyingpingtai_api/cmsbackstage/cmsAdInfo/queryAdInfoByPage
 * 列表
 *  @param {*} data 
 * @returns 
 */
export const queryAdInfoByPage = (data) => {
    return request({
        url: "cmsbackstage/cmsAdInfo/queryAdInfoByPage",
        method: "post",
        data,
    })

}

/**
 * POST
 * /qixianggongyingpingtai_api/cmsbackstage/cmsAdPosition/queryAdPositionAll
 * 广告位列表
 *  @param {*} data 
 * @returns 
 */
export const queryAdPositionAll = (data) => {
    return request({
        url: "cmsbackstage/cmsAdPosition/queryAdPositionAll",
        method: "post",
        data,
    })

}