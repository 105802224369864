<template>
  <div class="add-advertise">
    <el-form
      :model="formData"
      ref="updateAdvertiseInfo"
      size="small"
      :rules="formRules"
      label-width="100px"
      style="width: 1000px; margin: 0 auto"
    >
      <el-form-item label="标题" prop="advTitle">
        <el-input
          v-model.trim="formData.advTitle"
          placeholder="标题"
          style="width: 500px"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="广告位" prop="adpId">
        <el-select
          v-model="formData.adpId"
          placeholder="请选择"
          style="width: 500px"
        >
          <el-option
            v-for="(item, index) in adpList"
            :key="index"
            :label="item.adpName"
            :value="item.adpId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跳转类型" prop="jumpType">
        <el-select
          v-model="formData.jumpType"
          placeholder="请选择"
          style="width: 500px"
        >
          <el-option label="外部链接" value="1"> </el-option>
          <el-option label="内部" value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="跳转链接"
        prop="advUrl"
        v-if="formData.jumpType == 1"
      >
        <el-input
          v-model.trim="formData.advUrl"
          placeholder="跳转链接"
          style="width: 500px"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="内容" prop="advMemo" v-if="formData.jumpType == 2">
        <ueditorWrap ref="ueditor" @getContent="getContent"></ueditorWrap>
      </el-form-item>
      <el-form-item label="缩略图" prop="advImage">
        <el-upload
          class="upload-demo"
          action=""
          :before-upload="beforeUpload"
          :limit="1"
          ref="batchImportUploadBtn"
          :http-request="fileuploadHandle"
          accept=".png, .jpg, .jpeg, .gif"
          :show-file-list="false"
        >
          <img
            v-if="formData.advImage"
            :src="
              '/qixianggongyingpingtai_api/common/app/file/fileDownLoad?filePath=' +
              formData.advImage
            "
            class="adv-img"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" class="el-upload__tip">
            只能上传png、jpg、gif格式的图片
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="cancleHandle">取 消</el-button>
        <el-button type="primary" @click="confirmHandle">确 定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ueditorWrap from "../../components/Ueditor";
import { fileupload, fileDownload } from "../../api/uploadfile";
import {
  addOrUpdateAdInfo,
  queryAdPositionAll,
  queryAdInfoById,
} from "../../api/advertiseManage";
import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    ueditorWrap,
  },
  data() {
    return {
      dialogVisible: false,
      formData: {
        advImage: "",
        jumpType: "1",
        advTitle: "",
        advUrl: "",
        advMemo: "",
        adpId: "",
        advId: "",
      },
      id: "", //广告id
      localEditType: "", //编辑还是新增
      adpList: [], //广告位列表
      formRules: {
        advTitle: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        jumpType: [
          {
            required: true,
            message: "请选择跳转类型",
            trigger: "change",
          },
        ],
        adpId: [
          {
            required: true,
            message: "请选择广告位",
            trigger: "change",
          },
        ],
        advUrl: [
          {
            required: true,
            message: "请输入外部链接",
            trigger: "blur",
          },
        ],
        advImage: [
          {
            required: true,
            message: "请上传缩略图",
          },
        ],
      },
    };
  },
  mounted() {
    this.localEditType = this.$route.query.localEditType;
    this.id = this.$route.query.id;
    this.getAdpList();
    if (this.localEditType == "add") {
      this.formData.advId = uuidv4().replace(/\-/g, "");
    } else if (this.localEditType == "edit") {
      if (this.id) {
        this.getDetail();
      }
    } else {
      this.$message.error("localEditType错误");
    }
  },
  methods: {
    //确定
    confirmHandle() {
      this.$refs["updateAdvertiseInfo"].validate((volid) => {
        if (volid) {
          addOrUpdateAdInfo(this.formData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: "提交成功",
                });
                this.$router.push("/home/informationManage/advertiseManage");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        }
      });
    },

    // 取消
    cancleHandle() {
      this.$router.push("/home/informationManage/advertiseManage");
    },

    // 回显
    getDetail() {
      queryAdInfoById({
        id: this.id,
      }).then((res) => {
        if (res.code == 1) {
          let resData = res.data || {};
          this.formData = {
            ...resData,
            jumpType: resData.jumpType + "",
          };
          this.$nextTick(() => {
            // console.log(this.$refs.ueditor);
            this.$refs.ueditor.setText(resData.advMemo);
          });

          //
        }
      });
    },

    // 广告位列表
    getAdpList() {
      queryAdPositionAll().then((res) => {
        if (res.code == 1) {
          let resData = res.data || {};
          this.adpList = resData || [];
        }
      });
    },
    // 上传文件
    fileuploadHandle(params) {
      console.log(params);
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            console.log("成功");
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            this.formData.advImage = data.nginxFileReadPath || "";
            console.log(this.formData.advImage);
          } else if (res.code == -1) {
            // this.updateingExpressInfo = false;
            //未登陆
            this.$router.push("/");
          } else {
            // this.updateingExpressInfo = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {});
    },
    beforeUpload(file) {
      // 上传之前禁用表单确定按钮，等上传完成才可点击
      // this.updateingExpressInfo = false;
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["png", "jpg", "jpeg", "gif"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传图片只能是 png、jpg、gif格式");
        return false;
      }
    },
    getContent(val) {
      this.formData.advMemo = val;
    },
  },
};
</script>

<style lang="less" scoped>
.add-advertise {
  .adv-img {
    display: block;
    width: 300px;
    height: 120px;
  }
  .upload-demo /deep/.el-upload {
    width: 300px;
    height: 120px;
    border: 1px dashed #e5e5e5;
    .el-icon-plus {
      margin-top: 40px;
      font-size: 40px;
      color: #e5e5e5;
    }
  }
}
</style>